<template>
  <div>
    <div
      class="untopped-order margin-top-bottom-twenty box-shadow-light-grey border-radius-6 flex-row-start-center"
    >
      <el-select
        class="margin-right-ten"
        v-model="currentOfficial"
        placeholder="请选择公众号"
        style="width: 140px;margin-right:10px"
        @change="changeData"
        filterable
        clearable
      >
        <el-option
          v-for="item in channelList"
          :key="item.id"
          :label="item.channelName"
          :value="item.id"
        >
          <span style="float: left">{{ item.channelName }}</span>
          <span
            style="float: right; margin-left: 15px; font-size: 13px"
          >{{ item.platformStr }} - {{ item.uname }}</span>
        </el-option>
      </el-select>
      <el-menu :default-active="activeSubMenu" active-text-color="#00bf8a" mode="horizontal" router>
        <el-menu-item
          :index="`${path}/list`"
          class="font-eighteen margin-right-23"
          @click.native="handleTabClick(`${path}/list`)"
        >列表</el-menu-item>
        <el-menu-item
          :index="`${path}/message`"
          class="font-eighteen margin-right-23"
          @click.native="handleTabClick(`${path}/message`)"
        >客服消息</el-menu-item>
      </el-menu>
    </div>
    <div class="untopped-part">
      <router-view
        :accountChannelId.sync="accountChannelId"
        :relationId.sync="relationId"
        :channelList="channelList"
        :currentOfficial="currentOfficial"
        ref="routeView"
      ></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  messagePlatformList
} from '@/assets/js/options'
export default {
  name: 'unToppedOrder',
  props: ['accountChannelId', 'relationId', 'channelList'],
  data () {
    return {
      path: '/message/unToppedOrder',
      tabName: this.$route.path,
      activeSubMenu: this.$route.path,
      currentOfficial: null
    }
  },
  watch: {
    $route (newVal) {
      this.activeSubMenu = newVal.path
      this.tabName = newVal.path
      this.$nextTick(() => {
        this.$refs.routeView.initData()
      })
    }
  },
  
  beforeRouteEnter (to, from, next) {
    next((vm) => vm.init())
  },
  methods: {
    init () {
      const account = sessionStorage.getItem('unToppedAccountId')
      this.currentOfficial = account
        ? Number(account) || null
        : this.channelList[0]
          ? this.channelList[0].id
          : null
      this.$nextTick(() => {
        this.$refs.routeView.initData()
      })
    },
    handleTabClick (tab) {
      this.tabName = tab
    },
    changeData (select) {
      // 不使用nextTick 传递过去的id值为上一次的值
      this.$nextTick(() => {
        this.$refs.routeView.initData()
        sessionStorage.setItem('unToppedAccountId', JSON.stringify(select))
      })
    }
  },
  components: {}
}
</script>
<style lang='scss' scoped>
.untopped-order {
  background: #fff;
  padding-left: 20px;
  /deep/ .el-menu-item,
  .el-submenu__title {
    height: 34px;
  }
}
.el-menu--horizontal > .el-menu-item {
  height: 52px;
  line-height: 52px;
}
.consume-part {
  box-shadow: 0px 0px 6px 0px #f1f4f6;
}
</style>
